form.default {
  .form-control {
    margin-bottom: 20px;
    font-weight: 300;
    min-height: 50px;
    transition: 500ms all;
    width: 100%;
    display: block;
    padding: 0;
    border:none;
    border-radius: 0;
    border-bottom: 4px solid $blue;

    &:focus {
      padding-left: 20px;
      box-shadow: none;
      border: none;
      border-bottom: 4px solid $blue;
    }
  }
}


.CheckboxContainer {
  margin-bottom: 24px;
  display: flex;
  align-items: center;

  a {
    color: $blue;
  }
}


.checkContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1rem;
  text-align: left;
  font-size: rem(14px);
  line-height: rem(18px);

  p {
    margin-top: 2px;
    margin-bottom: 0;
  }
}


  .checkContainer {
    @include breakpoint (medium) {
      margin-bottom:0;
    }
  }


.checkContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid rgba(37, 40, 49, 0.3);
  -webkit-transition: 500ms all;
  transition: 500ms all;
  overflow: hidden;
}

.checkmark:before {
  content: '\f00c';
  position: absolute;
  left: 50%;
  top: 2px;
  -webkit-transform: translateX(-200%);
  transform: translateX(-200%);
  font-family: $icon-font;
  color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 300ms all;
  transition: 300ms all;
}

.checkContainer:hover input ~ .checkmark {
  background-color: rgba(37, 40, 49, 0.3)
}

.checkContainer input:checked ~ .checkmark {
  background-color: #00358E;
  border: 1px solid #00358E
}

.checkContainer input:checked ~ .checkmark:before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none
}

.checkContainer input:checked ~ .checkmark:after {
  display: block
}

x.checkContainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg)
}

input.error + .checkmark {
  background: rgba(255, 0, 0, 0.1)
}

input.error, textarea.error, select.error {
  border-bottom: 4px solid #ea5744 !important;

  .select2-container--default {
    border-bottom: 4px solid #ea5744 !important;
  }
}

select.error + .select2-container--default {
  border-bottom: 4px solid #ea5744 !important;
}

.w-form-done, .w-form-fail {
  position: absolute;
  left: 50%;
  width: 70%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  font-size: 30px;
  -webkit-transition: 500ms all;
  transition: 500ms all;
  opacity: 0;
  line-height: 34px;
  z-index: 999;
  text-align: center;
  color: $dark;
  
  i {
    display: block;
    font-size: rem(80px);
    color: limegreen;
    position: relative;

  }
}

.w-form-fail i {
  color: #ea5744;
}

.w-form-done span, .w-form-fail span {
  color: rgba($dark, .3);
}

.w-form-done.animate-in, .w-form-fail.animate-in {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%) scale(1);
  opacity: 1
}

.animate-out {
  opacity: 0;
}

.input-field {
  position: relative;
  margin-top: 1rem;
  display: block;
  width: 100%;
  margin-bottom: 40px;
}

input[type="text"], textarea, select.js-example-basic-single {
  border: none;
  border-radius: 0;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 1rem;
  box-shadow: none;
  box-sizing: content-box;
  transition: all 0.3s;
  border-bottom: 4px solid $blue;

  &:focus {
    border-bottom: 4px solid $blue !important;
  }

}

textarea {
  height: 7rem;
}

.input-field input[type="text"]:focus + label{
  font-size: rem(14px);
}

.file-upload {
  background-color: #ffffff;
}

.btn-file {
  width: 100%;
  margin: 0;
  color: rgba($dark, .3);
  background: #f2f2f2;
  border: none;
  padding: 20px 0;
  border-radius: 0;
  transition: all .2s ease;
  outline: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin-bottom: 40px;

  i {
    font-size: rem(26px);
    margin-right: 10px;
  }
  
  &:hover {

    color: $light;
  }

}


.file-upload-btn:active {
  border: 0;
  transition: all .2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #1FB264;
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: #1FB264;
  border: 4px dashed #ffffff;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;

}

.image-title {
  color: rgba($dark, .5);

}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
  margin-bottom: 20px;
}

.remove-image {
  margin: 0;
  color: $dark;
  background: none;
  border: none;
  transition: all .2s ease;
  outline: none;
  padding: 10px;
  font-size: rem(14px);
  border: 1px solid rgba(red, .3);
  display: block;
  width: 100%;
  position: relative;
  margin-bottom: 30px;
  cursor: pointer;
}


.remove-image:active {
  border: 0;
  transition: all .2s ease;
}


  .select2-container--default{
    padding: 0;
    padding-bottom: 0;
    border-bottom: 4px solid $blue;
    margin-bottom: 40px;

    .select2-selection--single {
      border: none;
      border-radius: 0;
      padding: 0;
      font-size: 1rem !important;

      .select2-selection__rendered {
        padding: 0;
        font-size: 1rem !important;
      }
    }
  }


.select2-container--default .select2-selection--multiple {
  border: none;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0;

  li {
    margin:0;
    margin-top: 4px;
    margin-right: 10px;
    background: none;
    font-size: rem(15px);
    padding: 5px 10px;

    span {
      transition: 400ms all;
      margin-right: 6px;
      font-weight: 300;
      font-size: rem(18px);
      margin-left: 6px;
    }
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  padding-left: 0;

}

.select2-container .select2-search--inline .select2-search__field {
  font-size: 1rem;
}

.select2-container {
    z-index: 9999;
}



.select2-container--default .select2-results > .select2-results__options {
   position: fixed !important;
   top: 0 !important;
   width: 100% !important;
   height: 100% !important;
   left: 50% !important;
   z-index: 999;
   transform: translateX(-50%);
   background: $light;
   max-height: none;
   transition: 500ms all;
   display: block !important;
   text-align: center;
   padding-top: 30px;
  overflow-y: scroll;
 }




#select2-drop-mask {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999999;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: none !important;
  color: $dark !important;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background: none !important;
  border: 1px solid $blue;
}

.select2-results__options {
  li {
    display: block;
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 3px;
    padding: 6px 10px;
    transition: 400ms all;
    border: 1px solid $light;
    font-size: rem(14px);

    &:last-child {
      background: $blue;
      color: $light !important;
      transition: 400ms all;
      margin-top: 20px;

      &.select2-results__option--highlighted {
        background: darken($blue, 10%) !important;
        color: $light !important;
      }
      
      &:hover {
        background: darken($blue, 10%) !important;
        color: $light !important;
      }
    }
  }
}

#firstMove, #secondMove, #thirdMove, #fourthMove, #fifthMove {
   transform: translateY(-6rem);
   transition: 400ms all;
   opacity: 0;
   animation-name: moveIn;
   animation-delay: 1.4s;
   animation-fill-mode: forwards;
   animation-duration: .5s;
   animation-timing-function: ease-out;
 }

#secondMove{
   animation-delay: 1.2s;
 }

#thirdMove{
  animation-delay: 1s;
}

#fourthMove{
  animation-delay: .8s;
}

#fifthMove{
  animation-delay: .6s;
}

@keyframes moveIn {
  0% {
    opacity: 0;
    transform: translateY(-6rem);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

.fa-spin {
  margin-right: 5px;
}

.CheckboxContainer {
  position: relative;
  transition: 400ms all;


  &:after {
    content: 'Einige Felder wurden nicht ausgefüllt.';
    position: absolute;
    left: 0;
    transition: 400ms all;
    right: 0;
    bottom: 0;
    background: #ea5744;
    text-align: center;
    color: white;
    font-family: $main-font;
    font-size: rem(14px);
    padding: 10px;
    opacity: 0;
    visibility: hidden;
  }

  &.nonValidForm {
    padding-bottom: 80px;

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered li span {
  position: absolute;
  left: 0;
  right:0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  padding-left: 25px !important;
  position: relative;
  transition: 500ms all;
  cursor: pointer;
  margin-right: 4px !important;
 

  &:hover {
    background: $blue;
    color: $light;
    border:1px solid $blue;
    
    &:before {
      color: $light;
    }
  }
  
  &:before {
    content: '\f00d';
    transition: 500ms all;
    position: absolute;
    left: 10px;
    top: calc(50% + 1px);
    transform:translateY(-50%);
    font-family: $icon-font;
    color: $dark;
    font-size: rem(12px);
  }
}

.btn-file {
  z-index: 9999 !important;
}