@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  src: url(fonts/fontawesome/fa-solid-900.eot);
  src: url(fonts/fontawesome/fa-solid-900.eot?#iefix) format('embedded-opentype'), url(fonts/fontawesome/fa-solid-900.woff2) format('woff2'), url(fonts/fontawesome/fa-solid-900.woff) format('woff'), url(fonts/fontawesome/fa-solid-900.ttf) format('truetype'), url(fonts/fontawesome/fa-solid-900.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  src: url(fonts/fontawesome/fa-regular-400.eot);
  src: url(fonts/fontawesome/fa-regular-400.eot?#iefix) format('embedded-opentype'), url(fonts/fontawesome/fa-regular-400.woff2) format('woff2'), url(fonts/fontawesome/fa-regular-400.woff) format('woff'), url(fonts/fontawesome/fa-regular-400.ttf) format('truetype'), url(fonts/fontawesome/fa-regular-400.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url(fonts/fontawesome/fa-light-300.eot);
  src: url(fonts/fontawesome/fa-light-300.eot?#iefix) format('embedded-opentype'), url(fonts/fontawesome/fa-light-300.woff2) format('woff2'), url(fonts/fontawesome/fa-light-300.woff) format('woff'), url(fonts/fontawesome/fa-light-300.ttf) format('truetype'), url(fonts/fontawesome/fa-light-300.svg#fontawesome) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  src: url(fonts/fontawesome/fa-brands-400.eot);
  src: url(fonts/fontawesome/fa-brands-400.eot?#iefix) format('embedded-opentype'), url(fonts/fontawesome/fa-brands-400.woff2) format('woff2'), url(fonts/fontawesome/fa-brands-400.woff) format('woff'), url(fonts/fontawesome/fa-brands-400.ttf) format('truetype'), url(fonts/fontawesome/fa-brands-400.svg#fontawesome) format('svg');
}