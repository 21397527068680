@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 100;
	src: url(fonts/webfont/Barlow-italic-100.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 200;
	src: url(fonts/webfont/Barlow-italic-200.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 300;
	src: url(fonts/webfont/Barlow-italic-300.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 400;
	src: url(fonts/webfont/Barlow-italic-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 500;
	src: url(fonts/webfont/Barlow-italic-500.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 600;
	src: url(fonts/webfont/Barlow-italic-600.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 700;
	src: url(fonts/webfont/Barlow-italic-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 800;
	src: url(fonts/webfont/Barlow-italic-800.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: italic;
	font-weight: 900;
	src: url(fonts/webfont/Barlow-italic-900.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 100;
	src: url(fonts/webfont/Barlow-normal-100.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 200;
	src: url(fonts/webfont/Barlow-normal-200.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 300;
	src: url(fonts/webfont/Barlow-normal-300.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 400;
	src: url(fonts/webfont/Barlow-normal-400.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 500;
	src: url(fonts/webfont/Barlow-normal-500.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 600;
	src: url(fonts/webfont/Barlow-normal-600.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 700;
	src: url(fonts/webfont/Barlow-normal-700.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 800;
	src: url(fonts/webfont/Barlow-normal-800.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

@font-face {
	font-family: 'Barlow';
	font-style: normal;
	font-weight: 900;
	src: url(fonts/webfont/Barlow-normal-900.woff) format('woff');
	unicode-range: U+0-10FFFF;
}

