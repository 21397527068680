header {

  background-size: cover;
  width: 100%;
  height: auto;
  position: relative;
  padding-bottom: 100px;


  @include breakpoint (medium) {
    background-attachment: fixed;
    background-size: auto;
    background-position-x: 50%;
    padding-top: 50px;


    &:before {
      content: '';
      width: 100%;
      height: 400px;
      position: fixed;
      top: -100px;
      opacity: .6;
      transform: translateY(10%);
      background-image: linear-gradient(to bottom, black 0%, rgba(black, 0) 50%)
    }

    &:after {
      content: '';
      width: 100%;
      height: 400px;
      position: fixed;
      bottom: 0;
      opacity: .6;
      transform: translateY(10%);
      background-image: linear-gradient(to top, black 0%, rgba(black, 0) 50%)
    }
  }

  @include breakpoint (giant) {
    background: none;
  }




  .box {
    position: relative;
    color: $dark;
    background: $light;
    width: 100%;
    max-width: rem(650px);
    overflow: hidden;
    z-index: 10;


    &:after {
      content: '';
      background: black;
      width: 80%;
      height: 120px;
      position: absolute;
      left: 50%;
      top: 0;
      border-radius: 50%;
      filter:blur(15px);
      z-index: 1;
      opacity: .3;
      transform: translateX(-50%);
    }

    @include breakpoint (medium) {
      transform: translateX(-50%);
      left: 50%;
    }

    .insideBox {
      padding: rem(40px);
      overflow: hidden !important;
      position: relative;
      margin: 0 auto;

      @include breakpoint (small) {
        padding: rem(110px);
      }

    }

    .navbar-brand {
      display: block;
      width: 100%;
      text-align: center;
      background: $light;
      padding: 40px 0;
      position: relative;
      z-index: 5;



      svg {
        max-width: rem(200px);

        @include breakpoint (medium) {
            max-width: rem(250px);
        }
      }
    }


    .smallText {
      margin-bottom: 0;
      font-size: rem(18px);

      @include breakpoint (medium) {
          font-size: rem(22px);
      }
    }

    .claim-headline {
      font-size: rem(28px);
      font-weight: 600;
      line-height: rem(32px);
      hyphens: auto;
      color: $blue;
      margin-bottom: 50px;

      @include breakpoint (medium) {
          font-size: rem(37px);
        line-height: rem(42px);
      }

    }
  }
}


.more {
  color: $light;
  margin: 0 auto;
  text-align: center;
  font-size: rem(15px);
  margin-top: 20px;
  position: relative;
  z-index: 6;
  
  a {
    color: #78a5f2;
    
    &:hover {
      color: white;
    }
  }
}

.imgHeader {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 2400px;


  @include breakpoint (huge) {

  }
}