* {
  list-style: none;
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-size: $font-size;
  line-height: 1.3rem;
  color: $dark;
}

body {
  overflow-x: hidden;

  &.openNav {
    position: fixed;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
  }

  @include font($font-size, $font-weight, $main-font);

  #page {
    overflow: hidden;

    &:before {
      transition: 500ms all;
      opacity: 0;
      content: '';
      width: 100%;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba($dark, .7);
      position: absolute;
      visibility: hidden;
      z-index: 1;
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

a {
  color: $light;
  transition: 500ms cubic-bezier(0.6, 0, 0, 0.5);
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: $blue;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  width: 100%;
}

.unveil {
  opacity: 0;
  transition: 400ms all;
  transform: translateY(5rem);

  &.unveil-loaded {
    opacity: 1;
    transform: none;
  }
}

.center {
  text-align: center;
}

.align-center {
  .row {
    align-items: center;
  }
}

.clearfix::after {
  display: block;
  content: "";
  clear: both;
}

.btn {
  background: $blue;
  padding: 1rem 2rem;
  color: $light;
  font-weight: 300;
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
  user-select: none;
  font-size: 1rem;
  position: relative;
  border-radius: 0;
  border:none;
  overflow: hidden;
  transition:400ms all;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: darken($blue, 5%);
    transition: 400ms all;
    transform: translateY(100%);
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    background: darken($blue, 10%);
    transition: 1s all;
    transform: translateY(150%);
  }

  span {
    position: relative;
    z-index: 15;
  }


  &:hover {
    color: $light;
    &:before, &:after {
      transform: none;
    }
  }
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

ul.styled {
  margin: 0;
  li {
    position: relative;
    padding-left: 2rem;
    margin-bottom: .5rem;

    &:last-child {
      margin-bottom: 0;
    }

    i {
      position: absolute;
      left: 0;
      top: 4px;
    }
  }
}

.videoFrame {
  width: 100%;
  height: rem(400px);
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
  line-height: 2.7rem;
}

h2, .h2 {
  font-size: 2rem;
  line-height: 2.2rem;
}

h3, .h3 {
  font-size: 1.5rem;
  line-height: 1.7rem;
}

h4, .h4 {
  font-size: 1.3rem;
  line-height: 1.3rem;
}

h5, .h5 {
  font-size: 1rem;
  line-height: 1.2rem;
}

h6, .h6 {
  font-size: 1rem;
  line-height: 1.2rem;
}