main {
  .container {
    padding-top: 100px;
    padding-bottom: 100px;

    &.next-container {
      padding-top: 0;
    }

    @include breakpoint (large) {
      padding-top: 150px;
      padding-bottom: 150px;

      &.next-container {
        padding-top: 0;
      }
    }
  }
}
