// Colors
$light: #ffffff;
$dark: #101820;
$blue: #00358e;

// Font
$main-font:  "Barlow", sans-serif;
$icon-font: "Font Awesome 5 Pro";
$font-size: 18px;
$font-weight: 300;

// Breakpoints
$breakpoint-default:  575px; //from 0px to 575px
$breakpoint-small:    576px;
$breakpoint-medium:   768px;
$breakpoint-large:    992px;
$breakpoint-giant:    1200px;
$breakpoint-huge:     1600px;


